import { render } from "solid-js/web";
import { Router, Route, Routes, A, useLocation } from "@solidjs/router";
import TestForm from "./pages/test_form/TestForm.js";
import { createSignal, createEffect } from "solid-js";
import CustomConversion from "./pages/custom_conversion/CustomConversion.js";
import "./App.scss";

const NavBar = () => (
  <div className="NavBar">
    <A href="/">Home</A>
    <A href="/test_form">Test Form</A>
    <A href="/custom_conversion">Custom Conversion</A>
  </div>
);

const Content = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = createSignal({});

  // Function to parse query string into an object
  const parseQueryString = () => {
    const params = new URLSearchParams(location.search);
    const queryObject = {};
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }
    setQueryParams(queryObject);
  };
  createEffect(() => {
    parseQueryString();
  });
  return (
    <div className="Content">
      <Routes>
        <Route
          end
          path="/"
          component={() => (
            <span className="Content-text">{"Hello A&M :)"}</span>
          )}
        />
        <Route end path="/test_form" component={() => <TestForm />} />
        <Route
          end
          path="/lead_gen_page"
          component={() => (
            <div>
              <span className="Content-text">{"lead gen"}</span>
              <br />
              <span className="Content-text">
                {"THIS IS AN EMAIL: "}
                <span id="findme">{queryParams()["email"]}</span>
              </span>
            </div>
          )}
        />
        <Route
          end
          path="/custom_conversion"
          component={() => <CustomConversion />}
        />
      </Routes>
    </div>
  );
};

const App = () => (
  <Router>
    <NavBar />
    <Content />
  </Router>
);

const rootElement = document.getElementById("root");
render(() => <App />, root);
